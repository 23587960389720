import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"

import {
  SectionContainer,
  SectionInnerWrapper,
} from "../../components/Layout/styled"

import Footer from "../../components/Layout/Footer"
import SeoComp from "../../components/SeoComp"

const RewardGameConditions = ({ intl, data }) =>
  data &&
  data.allDirectusRewardGameConditionTranslation.nodes.length > 0 && (
    <Fragment>
      <SeoComp
        description={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].description
        }
        title={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].title
        }
        twitter_card={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].twitter_card
        }
        image={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].image &&
          data.allDirectusSeoTranslation.nodes[0].image.data &&
          data.allDirectusSeoTranslation.nodes[0].image.data.url
        }
      />
      <SectionContainer>
        <SectionInnerWrapper align="flex-start" justify="flex-start">
          {data.allDirectusRewardGameConditionTranslation.nodes[0].title && (
            <Title
              dangerouslySetInnerHTML={{
                __html:
                  data.allDirectusRewardGameConditionTranslation.nodes[0].title,
              }}
            />
          )}
          {data.allDirectusRewardGameConditionTranslation.nodes[0].content && (
            <TextBox
              dangerouslySetInnerHTML={{
                __html:
                  data.allDirectusRewardGameConditionTranslation.nodes[0]
                    .content,
              }}
            />
          )}
        </SectionInnerWrapper>
      </SectionContainer>

      {data.allDirectusFooterTranslation.nodes.length > 0 && (
        <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
      )}
    </Fragment>
  )

const Title = styled.h1`
  color: #262626;
  font-size: 28px;
  line-height: 1.05;
  letter-spacing: 0.05em;
  font-family: "BebasNeueBold";
  font-weight: bold;
`

const TextBox = styled.div`
  color: #777;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  letter-spacing: 0;

  > p {
    margin-bottom: 15px;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusRewardGameConditionTranslation(
      filter: { language: { eq: $lang } }
    ) {
      nodes {
        title
        content
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "jeuconcours_parrainage" } }
      }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(RewardGameConditions)
